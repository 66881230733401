import { SVGProps } from "./types";

export const CheckUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 11L7 15L17 5"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CheckCircleUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.66667 10L9.22222 11.5556L12.3333 8.44444M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
